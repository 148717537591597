'use client'
import HolyLoader from 'holy-loader'
// theme
import colorPalette from '@/ThemeRegistry/themePalette'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useLayoutEffect, useState } from 'react'

export default function PagetopLoader() {
   const pathname = usePathname()
   const router = useRouter()
   const [isOnSearchPage, setIsOnSearchPage] = useState(false)

   useLayoutEffect(() => {
      setIsOnSearchPage(pathname.indexOf('/search') !== -1)
   }, [pathname, router])

   return (
      <>
         {isOnSearchPage && (
            <HolyLoader
               color={colorPalette.primary.main}
               height={5}
               speed={200}
               easing="ease"
               showSpinner={false}
               initialPosition={0.95}
               zIndex={1600}
            />
         )}
         {!isOnSearchPage && (
            <HolyLoader
               color={colorPalette.primary.main}
               height={5}
               speed={200}
               easing="ease"
               showSpinner={false}
               initialPosition={0.08}
               zIndex={1600}
            />
         )}
      </>
   )
}
