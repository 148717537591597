'use client'

// mui core
import { GlobalStyles } from '@mui/material'

export const GlobalStyleCollection = {
   html: {
      maxWidth: '100vw',
      overflowX: 'hidden',
      textRendering: 'optimizeLegibility',
      scrollBehavior: 'smooth',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
   },
   body: {
      maxWidth: '100vw',
      overflowX: 'hidden',
      paddingRight: '0 !important',
   },
   a: {
      color: 'inherit',
      textDecoration: 'none',
   },
   'input:-webkit-autofill,\ninput:-webkit-autofill:hover, \ninput:-webkit-autofill:focus, \ninput:-webkit-autofill:active':
      {
         WebkitBackgroundClip: 'text',
         WebkitTextFillColor: '#ffffff',
         transition: 'background-color 5000s ease-in-out 0s',
         boxShadow: 'inset 0 0 20px 20px #23232329',
      },
   'nextjs-portal': {
      display: 'none',
   },
}

export const GlobalStylesheet = () => <GlobalStyles styles={GlobalStyleCollection} />
