'use client'

/* redux core */
import { Provider } from 'react-redux'
import { reduxStore } from '@/store/redux'
import { persistStore } from 'redux-persist'

persistStore(reduxStore)

export default function ReduxProvider({ children }: { children: React.ReactNode }) {
   return <Provider store={reduxStore}>{children}</Provider>
}
